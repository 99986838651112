/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Flex",
      color: "info",
      data: [0, 1, 1, 1, 2, 2, 3, 3, 4],
    },
    {
      label: "Midlance",
      color: "dark",
      data: [0, 0, 0, 1, 1, 2, 2, 2, 2],
    },
  ],
};
