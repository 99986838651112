/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// sudee solutions React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Billing page components
import Bill from "layouts/billing/components/Bill";
import WhatsappGroup from "components/WhatsappGroup";

function BillingInformation() {
  return (
    <Card id="delete-account">
      <SuiBox pt={3} px={2}>
        <SuiTypography variant="h6" fontWeight="medium">
          Current Openings
        </SuiTypography>
        <WhatsappGroup />
      </SuiBox>
      <SuiBox pt={1} pb={2} px={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Bill
            name="Senior Java Developer"
            company="Semiconductor, Warehouse, Airport"
            email="Java 8, Spring boot, REST API, Micro services, Kafka, MQ, SQL/NoSQL DB"
            vat="~5 years"
          />
          <Bill
            name="Senior Frontend Developer"
            company="Semiconductor, Healthcare"
            email="Angular/ ReactJS, HTML5, CSS3, Jest, Cypress, ES6, Typescript"
            vat="~6 years"
          />
          <Bill
            name="Senior Automation tester"
            company="Semiconductor, Digital Imaging"
            email="Manual testing, Web based testing, Selenium"
            vat="~5 years"
            noGutter
          />
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default BillingInformation;
