/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// sudee solutions React components
import SuiBox from "components/SuiBox";
import WhatsappGroup from "components/WhatsappGroup";
import SuiTypography from "components/SuiTypography";

// Images
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";

function BuildByDevelopers() {
  return (
    <Card>
      <SuiBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>
            <SuiBox display="flex" flexDirection="column" height="100%">
              <SuiBox pt={1} mb={0.5}>
                <SuiTypography variant="h5" fontWeight="bold" gutterBottom>
                  Sudee Solutions BV
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="h6" textColor="text" fontWeight="light" mb={2}>
                is a recruitment/software company based in the Netherlands. Our mission is to help
                our clients in finding the best software talent or build software solutions for
                their business. We are one of a kind that offers a unique revenue sharing model and
                Offer permanent contract with client.
              </SuiTypography>
              <SuiBox mb={0}>
                <SuiTypography variant="h6" textColor="secondary" fontWeight="regular">
                  Happy Developers, Revenue sharing, High Impact.
                </SuiTypography>
              </SuiBox>
              <WhatsappGroup></WhatsappGroup>
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={5} className="ml-auto relative">
            <SuiBox
              height="100%"
              display="grid"
              justifyContent="center"
              alignItems="center"
              backgroundColor="info"
              borderRadius="lg"
              backgroundGradient
            >
              <SuiBox
                component="img"
                src={wavesWhite}
                alt="waves"
                display="block"
                position="absolute"
                left={0}
                width="100%"
                height="100%"
              />
              <SuiBox component="img" src={rocketWhite} alt="rocket" width="100%" pt={3} />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default BuildByDevelopers;
