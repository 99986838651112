/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// sudee solutions React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";

function Invoices() {
  return (
    <Card id="delete-account">
      <SuiBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6" fontWeight="medium">
          Employees workplace
        </SuiTypography>
        <SuiButton variant="outlined" buttonColor="info" size="small">
          view all
        </SuiButton>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Invoice date="Employee 1" id="~6 months" price="Healthcare" />
          <Invoice date="Employee 2" id="~4 months" price="Healthcare" />
          <Invoice date="Employee 3" id="~7 months" price="Semiconductor" />
          <Invoice date="Employee 4" id="~4 months" price="Warehouse" />
          <Invoice date="Employee 5" id="~1 month" price="Digital Imaging" />
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Invoices;
