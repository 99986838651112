/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// sudee solutions PRO Material-UI components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// sudee solutions PRO Material-UI example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card className="h-100">
      <SuiBox pt={3} px={3}>
        <SuiTypography variant="h6" fontWeight="medium">
          Interview preparation Overview
        </SuiTypography>
        <SuiBox mt={1} mb={2}>
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            <SuiTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon className="font-bold text-success">arrow_upward</Icon>
            </SuiTypography>
            &nbsp;
            <SuiTypography variant="button" textColor="text" fontWeight="medium">
              95%
            </SuiTypography>{" "}
            success
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox p={2}>
        <TimelineItem
          color="success"
          icon="notifications"
          title="Resume screening"
          dateTime="First discussion"
        />
        <TimelineItem
          color="error"
          icon="inventory_2"
          title="Proposal to client"
          dateTime="Feedback loop"
        />
        <TimelineItem
          color="info"
          icon="shopping_cart"
          title="Preparation for client interview"
          dateTime="Second discussion"
        />
        <TimelineItem
          color="warning"
          icon="payment"
          title="Interview cracking tips (Soft skills, technical, dutch culture, mock interviews)"
          dateTime="Third discussion"
        />
        <TimelineItem
          color="primary"
          icon="vpn_key"
          title="Client interview 1"
          dateTime="With technical panel"
        />
        <TimelineItem
          color="primary"
          icon="vpn_key"
          title="Client interview 2"
          dateTime="With hiring manager"
        />
        <TimelineItem
          color="dark"
          icon="paid"
          title="Roll out offer"
          dateTime="Visa sponsor, Housing"
        />
      </SuiBox>
    </Card>
  );
}

export default OrdersOverview;
