import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function WhatsappGroup() {
  const { socialMediaColors } = colors;
  const { size } = typography;

  return (
    <SuiBox mt={2} display="flex" justifyContent="center" alignItems="center">
      <SuiTypography variant="h6" textColor="secondary" fontWeight="bold">
        Join our whatsapp group
      </SuiTypography>
      <SuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        key={"whatsapp-group"}
        component="a"
        href={"https://chat.whatsapp.com/DwFytDAVKqR0592AaiQeKG"}
        target="_blank"
        rel="noreferrer"
        fontSize={size.xl}
        color={socialMediaColors["whatsapp"].main}
        pr={1}
        pl={0.5}
        mt={-0.1}
      >
        {<WhatsAppIcon />}
      </SuiBox>
    </SuiBox>
  );
}
export default WhatsappGroup;
