/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-github-btn
import GitHubButton from "react-github-btn";

// clsx is a utility for constructing className strings conditionally
import clsx from "clsx";

// @mui material components
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// sudee solutions React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SidenavCard from "examples/Sidenav/SidenavCard";
import BillingInformation from "layouts/billing/components/BillingInformation";

// Custom styles for the Configurator
import styles from "examples/Configurator/styles";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// sudee solutions React context
import { useSoftUIController } from "context";

function Configurator() {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, transparentSidenav, fixedNavbar, sidenavColor } = controller;
  const [disabled, setDisabled] = useState(false);
  const classes = styles({ sidenavColor });
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => {
    dispatch({ type: "OPEN_CONFIGURATOR", value: false });
  };

  const handleTransparentSidenav = () => {
    dispatch({ type: "TRANSPARENT_SIDENAV", value: true });
  };

  const handleWhiteSidenav = () => {
    dispatch({ type: "TRANSPARENT_SIDENAV", value: false });
  };

  const handleFixedNavbar = () => {
    dispatch({ type: "FIXED_NAVBAR", value: !fixedNavbar });
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.configurator, {
          [classes.configurator_open]: openConfigurator,
          [classes.configurator_close]: !openConfigurator,
        }),
      }}
    >
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SidenavCard />
        </SuiBox>

        <Icon
          className={`font-bold ${classes.configurator_close_icon}`}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SuiBox>

      <Divider />

      <SuiBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        justifyContent="center"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox mb={2}>
          <MiniStatisticsCard
            title={{ text: "Senior Java Developer" }}
            count=""
            percentage={{ color: "info", text: "Java8/11, Spring boot" }}
            icon={{
              color: "info",
              component: "visibility_off",
            }}
          />
        </SuiBox>

        <SuiBox mb={2}>
          <MiniStatisticsCard
            title={{ text: "Senior Frontend Developer" }}
            count=""
            percentage={{ color: "info", text: "Angular/ReactJS/Vue" }}
            icon={{
              color: "info",
              component: "visibility_off",
            }}
          />
        </SuiBox>

        <SuiBox mb={2}>
          <MiniStatisticsCard
            title={{ text: "Senior tester" }}
            count=""
            percentage={{ color: "info", text: "Manual, Automation" }}
            icon={{
              color: "info",
              component: "visibility_off",
            }}
          />
        </SuiBox>
      </SuiBox>

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox mt={3} textAlign="center">
          <SuiBox display="flex" justifyContent="center">
            <SuiBox mr={1.5}>
              <SuiButton
                component={Link}
                href="//www.linkedin.com/company/sudee-solutions"
                target="_blank"
                rel="noreferrer"
                buttonColor="dark"
              >
                <LinkedInIcon />
                &nbsp; Follow
              </SuiButton>
            </SuiBox>
          </SuiBox>
          <SuiBox mb={0.5}>
            <SuiTypography variant="h6">Thank you for following!</SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Drawer>
  );
}

export default Configurator;
