/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// sudee solutions PRO Material-UI components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for the SidenavCard
import styles from "examples/Sidenav/styles/sidenavCard";

// sudee solutions PRO Material-UI context
import { useSoftUIController } from "context";

function SidenavCard() {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const classes = styles({ miniSidenav, sidenavColor });

  return (
    <Card className={classes.card}>
      <CardContent className={classes.card_content}>
        <SuiBox customClass={classes.card_iconBox}>
          <Icon className={classes.card_icon} fontSize="default">
            star
          </Icon>
        </SuiBox>
        <SuiBox>
          <SuiTypography variant="h6" textColor="white">
            We make your career grow
          </SuiTypography>
          <SuiBox mb={1.5} mt={-1}>
            <SuiTypography variant="caption" textColor="white" fontWeight="medium">
              Please send email to:
            </SuiTypography>
          </SuiBox>
          <SuiTypography variant="h6" textColor="white" fontWeight="light">
            hr@sudeesolutions.com
          </SuiTypography>
        </SuiBox>
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
