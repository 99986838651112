/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

// sudee solutions React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// sudee solutions React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import semiconductor from "assets/images/semiconductor.jpg";
import dcdx from "assets/images/dcdx.jpg";
import designsystem from "assets/images/designsystem.jpeg";
import radiology from "assets/images/radiology.jpg";
import dhl from "assets/images/dhl.jpg";

function Overview() {
  return (
    <DashboardLayout>
      <Header />
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="profile information"
              description="Hi, I’m Suraj KC born and bought up in India. I have been living in Netherlands over 6 years and I work as a Software Developer. I always follow the theory of: If you can’t decide, the answer is no. If there are two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
              info={{
                fullName: "Suraj, Kamdur Chidambaresh",
                mobile: "(+31) 616 330 149",
                email: "suraj@sudeesolutions.com",
                location: "Eindhoven, Netherlands",
                interests: "Coding, Clean code, KISS, DRY, Design patterns",
              }}
              social={[
                {
                  link: "https://www.facebook.com/suraj.started",
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: "https://linkedin.com/in/suraj-kc-7a583719",
                  icon: <LinkedInIcon />,
                  color: "twitter",
                },
                {
                  link: "https://www.instagram.com/suraj599",
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
              ]}
              action={{ route: "", tooltip: "Edit Profile" }}
            />
          </Grid>
          <Grid item xs={12} xl={8}>
            <ProfilesList title="Work Experience (~10 Years)" profiles={profilesListData} />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Card>
          <SuiBox pt={2} px={2}>
            <SuiBox mb={0.5}>
              <SuiTypography variant="h6" fontWeight="medium">
                Projects
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1}>
              <SuiTypography variant="button" fontWeight="regular" textColor="text">
                High tech Scalable Web applications
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} xl={4}>
                <DefaultProjectCard
                  image={semiconductor}
                  label="ASML (D&E Research)"
                  title="FlexOVO Subrecipe, Scanner Matching"
                  description="Visualize scanner machines performance, get insights into wafer performance. Optimize by tuning several parameters to get effective chips."
                  action={{
                    route: "https://www.asml.com/en",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[]}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <DefaultProjectCard
                  image={dcdx}
                  label="Philips Healthcare (Research)"
                  title="Digital Cognitive Diagnostics"
                  description="A digital Cognitive software for Radiologists to asses stroke patients severity by conducting cognitive tests, infer data science/model results."
                  action={{
                    route: "https://www.youtube.com/watch?v=U5KipcjPtz8",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <DefaultProjectCard
                  image={designsystem}
                  label="Vanderlande (Walmart)"
                  title="Webcomponents Design System"
                  description="A living Design system consisting of 40+ web components which is a standard for all web applications within company."
                  action={{
                    type: "external",
                    route: "https://www.vanderlande.com/",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <DefaultProjectCard
                  image={radiology}
                  label="GE Healthcare (UMCU)"
                  title="Radiology Imaging system"
                  description="Centralized imaging system for viewing all scans of a patient to diagnose further treatment. Intelligent algorithm that allocates specialized radiology to patients."
                  action={{
                    type: "external",
                    route: "https://www.gehealthcare.com/",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <DefaultProjectCard
                  image={dhl}
                  label="Infosys (DHL)"
                  title="Denied parties application"
                  description="Handling of sending/blocking materials over cross border country by looking into regulations and denying parties who are blocked internationally and report to external system."
                  action={{
                    type: "external",
                    route:
                      "https://www.dhlexpress.nl/nl/kennisbank/ondersteuning/denied-party-screening-dps",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <PlaceholderCard
                  title={{ variant: "h5", text: "Many more outsourced projects..." }}
                  outlined
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      </SuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
