/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import Link from "@mui/material/Link";

// @mui material components
import Card from "@mui/material/Card";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// sudee solutions React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Divider from "@mui/material/Divider";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import WhatsappGroup from "components/WhatsappGroup";

function SignUp() {
  const [agreement, setAgremment] = useState(true);

  return (
    <BasicLayout
      title="Welcome!"
      description="Questions ? we are happy to help you."
      image={curved6}
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            Sudee Solutions BV
          </SuiTypography>
        </SuiBox>
        <SuiBox pb={2} px={2}>
          <SuiBox display="flex" flexDirection="column" alignItems="center">
            <SuiTypography variant="button" fontWeight="medium" textColor="text">
              Juralaan 21, 5627CJ
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="medium" textColor="text">
              Eindhoven, Netherlands
            </SuiTypography>
            <Divider />
            <SuiTypography variant="button" fontWeight="small" textColor="info">
              Phone: +31 616330149
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="small" textColor="info">
              Email: hr@sudeesolutions.com
            </SuiTypography>
            <WhatsappGroup />
            <SuiTypography
              component="a"
              href="#"
              variant="button"
              fontWeight="bold"
              textGradient
            ></SuiTypography>
          </SuiBox>
          <SuiBox mt={4} mb={1} display="flex" flexDirection="column" alignItems="center">
            <SuiButton
              variant="gradient"
              component={Link}
              href="//www.linkedin.com/company/sudee-solutions"
              target="www.linkedin.com/company/sudee-solutions"
              rel="noreferrer"
              buttonColor="dark"
            >
              <LinkedInIcon />
              &nbsp; Follow
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
