/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "App";

// sudee solutions React Context Provider
import { SoftUIControllerProvider } from "context";

ReactDOM.render(
  <HashRouter>
    <SoftUIControllerProvider>
      <App />
    </SoftUIControllerProvider>
  </HashRouter>,
  document.getElementById("root")
);
