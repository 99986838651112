/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};
