/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  chart: {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Gross salary", data: [58, 58, 58, 58, 58, 58, 58, 58, 111.36] },
  },
  items: [
    {
      icon: { color: "primary", component: "payment" },
      label: "Hourly rate",
      progress: { content: "60.3", percentage: 60 },
    },
    {
      icon: { color: "info", component: "payment" },
      label: "Total turnover",
      progress: { content: "108.540", percentage: 90 },
    },
    {
      icon: { color: "info", component: "payment" },
      label: "Total gross",
      progress: { content: "86,986", percentage: 90 },
    },
    {
      icon: { color: "warning", component: "payment" },
      label: "Wage tax",
      progress: { content: "19,419", percentage: 30 },
    },
    {
      icon: { color: "success", component: "payment" },
      label: "Avg hours",
      progress: { content: "150 p/m", percentage: 100 },
    },
    {
      icon: { color: "warning", component: "payment" },
      label: "Net income",
      progress: { content: "5,630p/m", percentage: 98 },
    },
    {
      icon: { color: "warning", component: "payment" },
      label: "With 30% ruling",
      progress: { content: "Y", percentage: 100 },
    },
  ],
};
