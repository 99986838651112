/**
=========================================================
* sudee solutions React - v2.0.0
=========================================================

* Product Page: https://www.sudeesolutions.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.sudeesolutions.com)

Coded by www.sudeesolutions.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import asml from "assets/images/asml.jpg";
import vanderlande from "assets/images/vanderlande.jpg";
import philips from "assets/images/philips.jpg";
import ge from "assets/images/ge.png";
import infosys from "assets/images/infosys.png";

export default [
  {
    image: asml,
    name: "ASML",
    description: "Senior Software developer",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label:
        "Java 11, Springboot, Angular, ReactJS, Redux, Typescript, ES6, Jest, Cypress, Bamboo CI",
    },
  },
  {
    image: vanderlande,
    name: "Vanderlande",
    description: "Senior Frontend Developer",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label: "Angular, StencilJS, Webcomponents, Micro-frontends, Design System",
    },
  },
  {
    image: philips,
    name: "Philips Healthcare",
    description: "Frontend Architect",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label:
        "Angular, Typescript, ES6, Jest, Cordova, iOS, C#, Micro services, REST, Algorithms development, Docker, TFS",
    },
  },
  {
    image: ge,
    name: "GE Healthcare",
    description: "Full Stack Developer",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label: "Java, J2EE, Springboot, RabbitMQ, Play2.0, RESTful Services, OSGi container",
    },
  },
  {
    image: infosys,
    name: "Infosys",
    description: "Software Developer",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label: "Core Java, Struts, Hiberanates, HTML, JSP, Javascript",
    },
  },
];
